import { analytics, analyticsSupported } from "../firebase";

const dumpEvent = (event_name, event_body) => {
    analyticsSupported
        .then((val) => {
            if (val) {
                console.log("dumping event " + event_name);
                analytics.logEvent(event_name, event_body);
            } else {
                console.log("analytics is not supported");
            }
        })
        .catch((reason) => {
            console.log(reason);
        });
};

export default dumpEvent;
